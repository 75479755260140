import { Box, Grid, Paper, Typography } from "@mui/material";
import earth from "../media/globe.png";
import React from "react";
import { Zoom } from "react-reveal";
import { styled } from "@mui/material/styles";
import Tada from "react-reveal/Tada";
const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	backgroundColor: "#8af35f",
	height: "20vh",
	color: "white",
	lineHeight: "60px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
}));

export default function SusteinM() {
	return (
		<Grid
			container
			direction="column"
			minHeight="60vh"
			width="90vw"
			justifyContent={"space-around"}
			ml={"10vw"}>
			<Grid item>
				<Grid container direction="column" spacing={2}>
					<Grid item width="90vw">
						<Typography variant="h2">Sustenta bilidade</Typography>
					</Grid>
					<Grid item width="90vw">
						<Typography variant="overline" align="center">
							A sustentabilidade mais do que nunca é um dos pilares da nossa
							sociedade. <br />
							Não podiamos ficar de fora da Revolução Sustentável e assim deixar
							a nossa marca positiva no nosso Ecosistema
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
