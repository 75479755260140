import { Button } from "@mui/material";
import React from "react";

export default function Book() {
	return (
		<Button variant="contained" href="https://www.livroreclamacoes.pt/Inicio/">
			Livro de reclamações online
		</Button>
	);
}
