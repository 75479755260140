import { Grid, Typography } from "@mui/material";
import card from "../media/card_NEXTEAM.png";
import React from "react";
import { Zoom } from "react-reveal";
export default function WhoWeAreM() {
	return (
		<Grid
			container
			direction="column"
			minHeight="90vh"
			width="90vw"
			justifyContent={"space-around"}
			ml={"10vw"}>
			<Grid item>
				<Grid container direction="column" maxWidth={"85vw"}>
					<Grid item>
						<Typography variant="h2"> Quem Somos </Typography>
					</Grid>
					<Grid item>
						<Typography variant="overline">
							Somos uma empresa líder na gestão de equipas técnicas de
							telecomunicações, energias solares e desenvolvimento web.Contamos
							com uma equipa altamente qualificada e especializada, que tem como
							objetivo garantir a excelência em cada projeto em que trabalhamos.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
