import { Box, Grid, Paper, Typography } from "@mui/material";
import earth from "../media/globe.png";
import React from "react";
import { Zoom } from "react-reveal";
import { styled } from "@mui/material/styles";
import Tada from "react-reveal/Tada";
const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	backgroundColor: "#8af35f",
	height: "20vh",
	color: "white",
	lineHeight: "60px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
}));

export default function Sustein() {
	return (
		<Grid
			container
			direction="row"
			minHeight="60vh"
			width="100vw"
			justifyContent={"space-around"}
			alignItems={"center"}>
			<Grid item>
				<Grid container direction="column" spacing={2} maxWidth={"50vw"}>
					<Grid item>
						<Typography variant="h2"> Sustentabilidade </Typography>
					</Grid>
					<Grid item width="50vw">
						<Typography variant="overline" pl="5">
							A sustentabilidade mais do que nunca é um dos pilares da nossa
							sociedade. <br />
							Não podiamos ficar de fora da Revolução Sustentável e assim deixar
							a nossa marca positiva no nosso Ecosistema
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item className="float">
				<img src={earth} width="400"></img>
			</Grid>
		</Grid>
	);
}
