import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

export default function CookiesPolicy() {
	const cookie = localStorage.getItem("cookieAcepted");

	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button variant="contained" onClick={handleClickOpen}>
				Politica de cookies
			</Button>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">
					{"Nossa Politica de cookies"}
				</DialogTitle>
				<DialogContent>
					<Grid container direction="column">
						<Grid>
							<Typography variant="overline">
								<b>Utilizamos cookies</b> para melhorar o desempenho e a sua
								experiência como utilizador no nosso site. O que são Cookies? As
								Cookies são pequenos ficheiros de texto que um site, quando
								visitado, coloca no computador do utilizador ou no seu
								dispositivo móvel, através do browser.
							</Typography>
						</Grid>
						<Grid>
							<Typography variant="overline">
								<b>A colocação de Cookies</b> ajudará o site a reconhecer o seu
								dispositivo numa próxima visita. As cookies retêm apenas
								informação relacionada com as suas preferências, não incluindo,
								como tal, os seus dados pessoais.
							</Typography>
						</Grid>
						<Grid>
							<Typography variant="overline">
								<b>Para que servem as Cookies?</b> As cookies são utilizadas
								para ajudar a determinar a utilidade, interesse e o número de
								utilizações dos sites, permitindo uma navegação mais rápida e
								eficiente e eliminando a necessidade de introduzir repetidamente
								as mesmas informações.
							</Typography>
						</Grid>
						<Grid>
							<Typography variant="overline">
								<b>Que tipos de Cookies existem?</b> Podem ser utilizados dois
								grupos de cookies: Cookies permanentes - Ficam armazenadas ao
								nível do navegador de internet (browser) nos seus dispositivos
								de acesso (PC, mobile e tablet) e que são utilizadas sempre que
								o utilizador faz uma nova visita ao nosso site. Geralmente são
								utilizadas para direcionar a navegação de acordo com os
								interesses do utilizador, permitindo-nos prestar um serviço mais
								personalizado. Cookies de sessão - São temporárias, permanecem
								no arquivo de cookies do seu navegador de internet (browser) até
								sair do site. A informação obtida através destas cookies permite
								identificar problemas e fornecer uma melhor experiência de
								navegação.
							</Typography>
						</Grid>
						<Grid>
							<Typography variant="overline">
								<b>Que tipos de Cookies existem?</b> Podem ser utilizados dois
								grupos de cookies: Cookies permanentes - Ficam armazenadas ao
								nível do navegador de internet (browser) nos seus dispositivos
								de acesso (PC, mobile e tablet) e que são utilizadas sempre que
								o utilizador faz uma nova visita ao nosso site. Geralmente são
								utilizadas para direcionar a navegação de acordo com os
								interesses do utilizador, permitindo-nos prestar um serviço mais
								personalizado. Cookies de sessão - São temporárias, permanecem
								no arquivo de cookies do seu navegador de internet (browser) até
								sair do site. A informação obtida através destas cookies permite
								identificar problemas e fornecer uma melhor experiência de
								navegação.
							</Typography>
						</Grid>
						<Grid>
							<Typography variant="overline">
								<b>Que tipos de Cookies utilizamos e para que fins?</b>
								Cookies essenciais - Algumas cookies são essenciais para aceder
								a áreas específicas do nosso site. Permitem a navegação no site
								e a utilização das suas aplicações, tal como aceder a áreas
								seguras do site através de login. Sem estas cookies, os serviços
								que o exijam não podem ser prestados. Cookies analíticas -
								Utilizamos estas cookies para analisar a forma como os
								utilizadores usam o site e monitorar a performance deste. Isto
								permite-nos fornecer uma experiência de alta qualidade ao
								personalizar a nossa oferta e rapidamente identificar e corrigir
								quaisquer problemas que surjam. Estas cookies são utilizadas
								anonimamente apenas para efeitos de criação e análise
								estatística, sem nunca recolher informação de caráter pessoal.
								Cookies de funcionalidade - As cookies de funcionalidade guardam
								as preferências do utilizador relativamente à utilização do
								site, de forma que não seja necessário voltar a configurar o
								site cada vez que o visita.
							</Typography>
						</Grid>
						<Grid>
							<Typography variant="overline">
								<b>Como pode gerir as Cookies?</b> Depois de autorizar o uso de
								Cookies, o utilizador pode sempre desativar parte ou a
								totalidade das nossas cookies. Todos os browsers permitem ao
								utilizador aceitar, recusar ou apagar cookies, nomeadamente
								através da seleção das definições apropriadas no respetivo
								navegador. Pode configurar as Cookies no menu “opções” ou
								“preferências” do seu browser. Note-se, no entanto, que, ao
								desativar as cookies, pode impedir que alguns serviços da web
								funcionem corretamente, afetando, parcial ou totalmente, a
								navegação no website. Mais informação sobre cookies em:
								www.alaboutcookies.org
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
