import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

export default function TermsAndConditions() {
	const cookie = localStorage.getItem("cookieAcepted");

	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button variant="contained" onClick={handleClickOpen}>
				Termos e condições
			</Button>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">
					{"Termos e condições"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Grid container direction="column">
							<Typography variant="overline">
								O seu acesso e uso do Serviço está condicionado à sua aceitação
								e conformidade com estes Termos. Estes Termos aplicam-se a todos
								os visitantes, utilizadores e outras pessoas que acedam ou usem
								o Serviço. Ao aceder ou usar o Serviço, concorda em ficar
								vinculado a estes Termos. Se não concordar com qualquer parte
								dos termos, então não pode aceder ao Serviço. Conteúdo O
								conteúdo das páginas deste site é apenas para sua informação
								geral e uso. Está sujeito a alteração sem aviso prévio. Nem nós,
								nem terceiros, fornecemos qualquer garantia quanto à precisão,
								pontualidade, desempenho, integridade ou adequação das
								informações e materiais encontrados ou oferecidos neste site
								para qualquer propósito específico. Reconhece que tais
								informações e materiais podem conter imprecisões ou erros e nós
								excluímos expressamente a responsabilidade por tais imprecisões
								ou erros na medida máxima permitida por lei. O uso de qualquer
								informação ou material neste site é por sua própria conta e
								risco, para o qual não devemos ser responsabilizados. Deve ter a
								sua própria responsabilidade garantir que quaisquer produtos,
								serviços ou informações disponíveis através deste site atinjam
								os seus requisitos específicos. Links para outros sites da Web O
								nosso Serviço pode conter links para sites ou serviços de
								terceiros que não pertencem nem são controlados pela NEXTEAM. A
								NEXTEAM não tem controlo e não assume nenhuma responsabilidade
								pelo conteúdo, políticas de privacidade ou práticas de sites ou
								serviços de terceiros. O utilizador também reconhece e concorda
								que a NEXTEAM não será responsável, direta ou indiretamente, por
								qualquer dano ou perda causada ou alegadamente causada ou
								relacionada com o uso ou dependência de tal conteúdo, bens ou
								serviços disponíveis em ou através de tais sites ou serviços.
								Alterações A NEXTEAM reserva o direito de, a qualquer altura,
								proceder a reajustamentos ou alterações aos presentes Termos e
								Condições, sendo essas alterações devidamente publicitadas.
								Contacte-nos Se tiver alguma dúvida ou comentário sobre estes
								Termos, entre em contacto connosco endereço de e-mail:
								geral@nexteam.pt ou envie uma carta para o nosso endereço: Rua
								Abilio Nunes Novo - Nro 6 4740-229 Esposende Portugal Tel: +351
								253 051 560 (Chamada para a rede fixa nacional)
							</Typography>
						</Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
