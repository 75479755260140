import { Grid, Paper, Typography } from "@mui/material";
import phone from "../media/cloud-phone.png";
import web from "../media/webapp.png";
import wallet from "../media/digital_wallet.png";
import React from "react";
import { Zoom } from "react-reveal";

import flyingThing from "../media/flying things.png";
import styled from "@emotion/styled";
const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	backgroundColor: "#f3f3f3",
	height: "40vh",
	color: "white",
	lineHeight: "60px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	borderRadius: "10px",
	zIndex: 20,
	margin: 0,
	"&:hover": {
		height: "41vh",
		backgroundColor: "#B7b9b6",
	},
}));
const Item2 = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	backgroundColor: "rgba(0,0,0,0.5)",
	height: "35vh",
	color: "white",
	lineHeight: "50px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	borderRadius: "10px",
	zIndex: -10,
	margin: -30,
}));

export default function Development() {
	return (
		<Grid
			container
			direction="column"
			minHeight="170vh"
			width="100vw"
			justifyContent={"space-around"}
			alignItems={"center"}
			margin={0}>
			<Grid item>
				<Grid
					container
					direction="row"
					justifyContent={"space-around"}
					alignItems={"center"}
					width="100vw">
					<Grid item className="float">
						<img src={phone} width="400"></img>
					</Grid>
					<Grid item maxWidth="30vw">
						<Typography variant="h4">Desenvolvimento Mobile</Typography>
						<Typography variant="overline">
							Aplicações Mobile Construidas de raiz utilizando React Native.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid
					container
					direction="row"
					justifyContent={"space-around"}
					alignItems={"center"}
					width="100vw">
					<Grid item maxWidth="30vw">
						<Typography variant="h4">Desenvolvimento Web</Typography>
						<Typography variant="overline">
							Aplicações WEB Construidas de raiz utilizando React.
						</Typography>
					</Grid>
					<Grid item className="float">
						<img src={web} width="400"></img>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid
					container
					direction="row"
					justifyContent={"space-around"}
					alignItems={"center"}
					width="100vw">
					<Grid item className="float">
						<img src={wallet} width="400"></img>
					</Grid>
					<Grid item maxWidth="30vw">
						<Typography variant="h4">Desenvolvimento Blockchain</Typography>
						<Typography variant="overline">
							Aplicações Reais com utilização da Blockchain.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
