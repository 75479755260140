import { Grid, Typography } from "@mui/material";
import logo from "../media/logo_full_size.png";
import React from "react";
import { Zoom } from "react-reveal";
export default function Intro() {
	return (
		<Grid
			container
			direction="row"
			minHeight="100vh"
			maxWidth="100vw"
			justifyContent={"space-around"}
			alignItems={"center"}>
			<Grid item>
				<img src={logo} width="400"></img>
			</Grid>
		</Grid>
	);
}
