import { Grid, Typography } from "@mui/material";
import card from "../media/card_NEXTEAM.png";
import React from "react";
import { Zoom } from "react-reveal";
export default function WhoWeAre() {
	return (
		<Grid
			container
			direction="row"
			minHeight="100vh"
			width="100vw"
			justifyContent={"space-around"}
			alignItems={"center"}>
			<Grid item>
				<Grid container direction="column" maxWidth={"50vw"}>
					<Grid item>
						<Typography variant="h2"> Quem Somos </Typography>
					</Grid>
					<Grid item>
						<Typography variant="caption">
							Somos uma empresa líder na gestão de equipas técnicas de
							telecomunicações, energias solares e desenvolvimento web.Contamos
							com uma equipa altamente qualificada e especializada, que tem como
							objetivo garantir a excelência em cada projeto em que trabalhamos.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item className="float">
				<img src={card} width="400"></img>
			</Grid>
		</Grid>
	);
}
