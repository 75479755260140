import "./App.css";
import { createTheme, Grid, ThemeProvider, Typography } from "@mui/material";
import "@fontsource/bungee";
import "@fontsource/bungee-hairline";
import "@fontsource/cagliostro";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import WhoWeAre from "./components/whoWeAre";
import Intro from "./components/intro";
import Technicians from "./components/technicians";
import Sustein from "./components/susteinability";
import SunPower from "./components/sunPower";
import Development from "./components/development";
import Separator from "./components/separator";
import Portfolio from "./components/portfolio";
import InNumbers from "./components/inNumbers";
import { isMobile } from "react-device-detect";
import IntroM from "./mobile-components/intro";
import SeparatorM from "./mobile-components/separator";
import WhoWeAreM from "./mobile-components/whoWeAre";
import TechniciansM from "./mobile-components/technicians";
import SusteinM from "./mobile-components/susteinability";
import SunPowerM from "./mobile-components/sunPower";
import DevelopmentM from "./mobile-components/development";
import InNumbersM from "./mobile-components/inNumbers";
import PortfolioM from "./mobile-components/portfolio";
import Footer from "./components/footer";
import Cookies from "./law/cookies";
import FooterM from "./mobile-components/footer";
const theme = createTheme({
	typography: {
		h2: {
			background:
				"-webkit-linear-gradient(to right, #f7b733, #fc4a1a)" /* Chrome 10-25, Safari 5.1-6 */,
			background: "linear-gradient(to right, #f7b733, #fc4a1a)",
			"-webkit-background-clip": "text",
			"-webkit-text-fill-color": "transparent",
			fontFamily: "Bungee",
		},
		h3: {
			fontFamily: "Bungee",
		},
		h4: {
			fontFamily: "Bungee",
		},
		h5: {
			fontFamily: "Bungee",
		},
		subtitle1: {
			fontFamily: "Bungee Hairline",
		},
		caption: {
			fontSize: "30px",
			fontFamily: "cagliostro",
		},
		overline: {
			fontSize: "15px",
			fontFamily: "cagliostro",
		},
	},
	palette: {
		primary: { main: "#111" },
	},
});

function App() {
	const [animationParent] = useAutoAnimate();
	if (!isMobile)
		return (
			<ThemeProvider theme={theme} ref={animationParent}>
				<Grid
					container
					direction="column"
					width="100vw"
					heigth="100vh"
					alignItems={"center"}
					alignContent={"center"}
					justifyContent="center"
					spacing={5}>
					<Cookies />
					<Grid item>
						<Intro />
					</Grid>
					<Grid item>
						<Separator />
					</Grid>
					<Grid item>
						<WhoWeAre></WhoWeAre>
					</Grid>
					<Grid item>
						<Separator />
					</Grid>
					<Grid item>
						<Technicians />
					</Grid>
					<Grid item>
						<Separator />
					</Grid>
					<Grid item>
						<Sustein />
					</Grid>
					<Grid item>
						<SunPower />
					</Grid>
					<Grid item>
						<Separator />
					</Grid>
					<Grid item>
						<Development />
					</Grid>
					<Grid item>
						<Separator />
					</Grid>
					<Grid item>
						<Portfolio />
					</Grid>
					<Grid item>
						<Separator />
					</Grid>
					<Grid item>
						<InNumbers />
					</Grid>
					<Grid item>
						<Footer />
					</Grid>
				</Grid>
			</ThemeProvider>
		);
	else {
		return (
			<ThemeProvider theme={theme} ref={animationParent}>
				<Grid
					container
					direction="column"
					maxWidth="100vw"
					heigth="100vh"
					alignItems={"center"}
					alignContent={"center"}
					justifyContent="center"
					overflow={"hidden"}
					spacing={5}>
					<Grid item>
						<IntroM />
					</Grid>
					<Grid item>
						<SeparatorM />
					</Grid>
					<Grid item>
						<WhoWeAreM></WhoWeAreM>
					</Grid>
					<Grid item>
						<SeparatorM />
					</Grid>
					<Grid item>
						<TechniciansM />
					</Grid>
					<Grid item>
						<SeparatorM />
					</Grid>
					<Grid item>
						<SusteinM />
					</Grid>
					<Grid item>
						<SunPowerM />
					</Grid>
					<Grid item>
						<SeparatorM />
					</Grid>
					<Grid item>
						<DevelopmentM />
					</Grid>
					<Grid item>
						<SeparatorM />
					</Grid>
					<Grid item>
						<PortfolioM />
					</Grid>
					<Grid item>
						<SeparatorM />
					</Grid>
					<Grid item>
						<InNumbersM />
					</Grid>
					<Grid item>
						<FooterM />
					</Grid>
				</Grid>
			</ThemeProvider>
		);
	}
}

export default App;
