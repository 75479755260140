import { Grid, Typography } from "@mui/material";
import React from "react";
import MyTeck from "../media/proposal.png";
export default function Portfolio() {
	return (
		<Grid
			container
			direction="column"
			justifyContent={"space-around"}
			minHeight="50vh"
			alignItems={"center"}
			width="100vw">
			<Grid>
				<Typography variant="h2">Portfólio</Typography>
			</Grid>
			<Grid
				container
				direction="row"
				justifyContent={"space-around"}
				alignItems={"center"}
				width="100vw">
				<Grid item>
					<img src={MyTeck} width="200"></img>
				</Grid>
				<Grid item maxWidth="30vw">
					<Typography variant="h4">
						MyTeck - gestão de Equipa técnica
					</Typography>
					<Typography variant="overline">
						Software as a Product In-house utilizado para gestão da nossa equipa
						técnica, que evoluiu para um Software as a Service
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}
