import { Grid, Paper, Typography } from "@mui/material";
import solarPanel from "../media/solar-pannel-wireless.png";
import React from "react";
import { Zoom } from "react-reveal";
import Tada from "react-reveal/Tada";
import styled from "@emotion/styled";
const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	backgroundColor: "#111",
	height: "20vh",
	width: "90vw",
	color: "white",
	lineHeight: "60px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	marginTop: "10px",
}));
export default function SunPowerM() {
	return (
		<Grid
			container
			direction="column"
			minHeight="150vh"
			width="90vw"
			ml={"10vw"}>
			<Grid item>
				<Grid
					container
					direction="column"
					spacing={5}
					width="100vw"
					justifyContent={"space-around"}>
					<Grid item>
						<Typography variant="h4" width="50vw">
							{" "}
							Paineis Solares{" "}
						</Typography>
					</Grid>
					<Grid item width="90vw">
						<Typography variant="overline" pl="5">
							Com as soluções que disponibilizamos, os nossos clientes podem
							desfrutar de opções de autoprodução e uso de energia altamente
							eficiente e personalizadas para o mercado residencial e
							empresarial, de acordo com as necessidades específicas de cada um.
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h5">
							Sim é verdade! Nós <br></br>fazemos{" "}
						</Typography>
					</Grid>
					<Grid item>
						<Grid
							container
							direction="column"
							justifyContent={"space-around"}
							width="100vw">
							<Grid item>
								<Item elevation={8}>
									Instalação
									<Typography variant="subtitle1">Super Rapida!</Typography>
								</Item>
							</Grid>
							<Grid item>
								<Item elevation={10}>
									Manutenção{" "}
									<Typography variant="subtitle1">de excelencia!</Typography>
								</Item>
							</Grid>
							<Grid item>
								<Item elevation={10}>
									Orçamentos
									<Typography variant="subtitle1">
										Personalizados
									</Typography>{" "}
								</Item>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
