import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

export default function Cookies() {
	const cookie = localStorage.getItem("cookieAcepted");
	const state = cookie === "true" ? true : !cookie ? true : false;
	const hasCookie = !cookie || cookie === "false";
	const [open, setOpen] = React.useState(hasCookie);
	const theme = useTheme();
	const [acepted, setAcepted] = React.useState(state);
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleCloseAgree = () => {
		localStorage.setItem("cookieAcepted", "true");
		setOpen(false);
	};
	const handleCloseDisagree = () => {
		localStorage.setItem("cookieAcepted", "false");
		setOpen(true);
		setAcepted(false);
	};

	return !!acepted ? (
		<div>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleCloseDisagree}
				aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">
					{"Nossa Politica de cookies"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Os cookies permitem nos proporcionar lhe uma experiência de
						navegação personalizada e segura. Utilizamos cookies e dispositivos
						similares nos nossos websites e outros serviços online que são
						necessários para o seu bom funcionamento técnico (cookies
						necessários). Adicionalmente, caso aceite, poderemos utilizar
						cookies para analisarmos informação estatística (cookies analíticos)
						e recordarmos as suas preferências (cookies de preferências).
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCloseDisagree}>
						Não Aceitar
					</Button>
					<Button onClick={handleCloseAgree} autoFocus>
						Aceitar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	) : (
		<div>
			<Dialog
				fullScreen={true}
				open={open}
				onClose={handleCloseDisagree}
				aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">
					{"Nossa Politica de cookies"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Typography variant="h5">
							Tem de Aceitar a utilização de cookies para continuar a vizualisar
							o nosso website
						</Typography>
					</DialogContentText>
					Os cookies permitem nos proporcionar lhe uma experiência de navegação
					personalizada e segura. Utilizamos cookies e dispositivos similares
					nos nossos websites e outros serviços online que são necessários para
					o seu bom funcionamento técnico (cookies necessários). Adicionalmente,
					caso aceite, poderemos utilizar cookies para analisarmos informação
					estatística (cookies analíticos) e recordarmos as suas preferências
					(cookies de preferências).
					<Button onClick={handleCloseAgree} autoFocus>
						Aceitar
					</Button>
				</DialogContent>
			</Dialog>
		</div>
	);
}
