import { Grid } from "@mui/material";
import React from "react";
import { Bounce } from "react-reveal";
import separator from "../media/separator.png";
export default function SeparatorM() {
	return (
		<>
			<Grid container direction="column" alignContent={"center"} width="100vw">
				<Grid item className="float">
					<img src={separator} width="100"></img>
				</Grid>
			</Grid>
		</>
	);
}
