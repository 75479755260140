import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

export default function PrivacyPolicy() {
	const cookie = localStorage.getItem("cookieAcepted");

	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button variant="contained" onClick={handleClickOpen}>
				Politica de Privacidade
			</Button>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">
					{"Nossa Politica de privacidade"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Grid container direction="column">
							<Typography variant="overline">
								A NEXTEAM, será a responsável pela recolha e processamento de
								dados pessoais registados neste site/domínio (nexteam.pt) e
								atuará em estrita conformidade com a Lei de Proteção de Dados e
								outras leis aplicáveis. A NEXTEAM assume e reconhece como
								fundamental um compromisso de: • Respeitar a sua privacidade e a
								seleção dos conteúdos que consulta neste site; • Identificar e
								limitar o tratamento dos dados pessoais eventualmente recolhidos
								ao estritamente necessário para concluir com sucesso a ação
								apresentada e solicitada; • Nos casos em que for necessário
								recolher os seus dados pessoais, o uso dessa informação é
								descrito nos termos do presente documento e com respeito pela
								legislação aplicável em matéria de privacidade e proteção de
								dados pessoais; • A não utilizar os seus dados para outros fins
								que não aqueles que tenham sido identificados e previamente
								comunicados; • A tratar os seus dados nos termos da legislação
								aplicável, acautelando as medidas de segurança e de proteção que
								se afigurem como necessárias e aplicáveis.e processamento de
								dados pessoais registados neste site/domínio (nexteam.pt) e
								atuará em estrita conformidade com a Lei de Proteção de Dados e
								outras leis aplicáveis. Porquê esta Política de Proteção de
								Dados Pessoais Nesta Política pretende-se dar a conhecer aos
								Clientes/Utilizadores as regras gerais de tratamento de dados
								pessoais, os quais são recolhidos e tratados no estrito respeito
								e cumprimento do disposto na legislação de proteção de dados
								pessoais em vigor em cada momento, nomeadamente o Regulamento
								(UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de
								abril de 2016 (“RGPD”). Respeitamos as melhores práticas no
								domínio da segurança e da proteção dos dados pessoais, tendo
								para o efeito tomado as medidas técnicas e organizativas
								necessárias por forma a cumprir o RGPD e garantir que o
								tratamento dos dados pessoais é lícito, leal, transparente e
								limitado às finalidades autorizadas. Estamos empenhados na
								proteção e confidencialidade dos dados pessoais, tendo adotado
								as medidas que consideramos adequadas para assegurar a exatidão,
								integridade e confidencialidade dos dados pessoais, bem como,
								todos os demais direitos. As regras previstas nesta Política de
								Proteção de Dados complementam as disposições, em matéria de
								proteção e tratamento de dados pessoais, previstas nos contratos
								que os Clientes/Utilizadores celebram connosco, assim como, as
								regras previstas nos termos e condições que regulam a oferta dos
								diversos produtos e serviços e que se encontram devidamente
								publicitadas no nosso website. O que Abrange esta Política de
								Proteção de Dados Aplica-se exclusivamente à recolha e
								tratamento de dados pessoais relativamente aos quais a NEXTEAM é
								responsável pelo respetivo tratamento, no âmbito dos serviços e
								produtos disponibilizados aos seus Clientes/Utilizadores e em
								todas as situações em que ocorra um tratamento de dados pessoais
								pela NEXTEAM, nomeadamente, através das ações de intervenção
								social e apoio ao desenvolvimento. Poderão estar incluídos links
								de acesso a outros websites que são alheios à NEXTEAM. Não
								podendo a NEXTEAM ser responsabilizada pela recolha e tratamento
								de dados pessoais efetuados através desses websites, nem
								assumindo a NEXTEAM qualquer responsabilidade relativamente a
								tais websites, nomeadamente quanto à respetiva exatidão,
								credibilidade e funcionalidades disponibilizadas nos mesmos. .
								Dados Pessoais Dados pessoais são qualquer informação, de
								qualquer natureza e independentemente do respetivo suporte,
								incluindo som e imagem, relativa a uma pessoa singular
								identificada ou identificável (titular dos dados). É considerada
								identificável a pessoa singular que possa ser identificada,
								direta ou indiretamente, designadamente por referência a um
								nome, número de identificação, dados de localização,
								identificadores por via eletrónica ou a um ou mais elementos
								específicos da sua identidade física, fisiológica, genética,
								mental, económica, cultural ou social. Responsável pelo
								Tratamento A NEXTEAM assume a qualidade de responsável pelo
								tratamento dos dados pessoais. Tratamento de Dados Pessoais O
								tratamento de dados pessoais consiste numa operação ou conjunto
								de operações efetuadas sobre dados pessoais ou conjuntos de
								dados pessoais, através de meios automatizados, ou não,
								designadamente a recolha, o registo, a organização, a
								estruturação, a conservação, a adaptação, a recuperação, a
								consulta, a utilização, a divulgação, difusão, comparação,
								interconexão, a limitação, o apagamento ou a destruição.
								Finalidade do Tratamento No presente âmbito, a NEXTEAM trata os
								seus dados pessoais com base num interesse legítimo e com o
								objetivo de, designadamente: • Responder às suas questões ou a
								prestar-lhe informações, designadamente nos casos em que
								preenche e envia os nossos formulários ou utiliza um endereço de
								email por nós disponibilizado; • Desenvolver e manter o presente
								site com a qualidade e segurança pretendida; • Contribuir para a
								prevenção e deteção de fraude; • Permitir o registo nos nossos
								eventos, formações e outras iniciativas; • Prestar os serviços
								inerentes à sua atividade e competências. Nos casos devidamente
								identificados, o tratamento de dados pessoais pela NEXTEAM pode
								ainda ser efetuado: • Na gestão da relação contratual, onde se
								incluem os contactos, via plataforma web, email e/ou telefone
								para notificações, esclarecimento de questões ou realização de
								inquéritos de satisfação e avaliação dos serviços prestados; •
								Se tiver obtido o seu consentimento, enquanto titular dos dados,
								para tratar os mesmos tendo por base finalidades específicas,
								explícitas e legítimas; • Quando for necessário para efeitos de
								cumprimento de obrigações legais que lhe sejam aplicáveis.
								Direitos dos Titulares dos Dados Enquanto titular de dados
								pessoais, poderá exercer os seguintes direitos: • Direito de
								aceder à informação que mantemos sobre si; • Direito a retificar
								os respetivos dados pessoais que se encontrem inexatos ou
								incompletos; • Direito ao apagamento dos respetivos dados
								pessoais; • Direito à limitação do tratamento dos seus dados
								pessoais; • Direito de portabilidade dos seus dados pessoais; •
								Direito de oposição ao tratamento dos seus dados pessoais; •
								Direito de reclamação à Comissão Nacional de Proteção de Dados
								Pessoais, www.cnpd.pt, caso considere estar a ser violado algum
								dos direitos elencados. Comunicação e Transferência de Dados
								Pessoais Os seus dados pessoais poderão ser comunicados ou
								transferidos às autoridades judiciais, Firmas e Denominações, às
								entidades a quem a lei atribua competências ao nível da
								investigação criminal, ou que tenham por missão a fiscalização
								ou prevenção do cumprimento da legislação no âmbito,
								designadamente da proteção dos direitos dos consumidores,
								propriedade intelectual, comunicações, segurança, saúde pública
								e práticas comerciais em geral. Apenas serão comunicados e
								transferidos os dados pessoais que se mostrem necessários ao
								efeito. Que dados são recolhidos Para além dos casos devidamente
								especificados e que decorrem, nomeadamente, da navegação no
								presente site com os contornos enunciados abaixo na Política de
								Cookies, através da utilização que faça deste site, e sempre
								mediante prévia ação voluntária do titular dos dados nesse mesmo
								sentido, podem ser tratados os seguintes dados pessoais: nome,
								endereço de email, contribuinte, telefone e morada de contacto.
								Prazo de conservação Serão estritamente cumpridos os prazos de
								conservação que resultam de forma inequívoca da lei aplicável.
								Para além dos dados pessoais que se enquadrem no âmbito
								descrito, os restantes dados pessoais tratados apenas pelo
								período necessário para as finalidades que motivaram a sua
								recolha, não se prevendo, salvo casos excecionais que podem
								decorrer designadamente da necessidade de manter um histórico de
								troca de mensagens escritas com um determinado utilizador, que o
								prazo de conservação exceda os seis meses. Encarregado da
								Proteção de Dados Compete ao encarregado da proteção de dados
								garantir, entre outros aspetos, a conformidade do tratamento de
								dados com a legislação em vigor. A NEXTEAM designou um
								Encarregado da Proteção de Dados Pessoais, pelo que poderá o
								mesmo ser contactado diretamente, utilizando os meios
								disponibilizados abaixo, sobre todas as questões relacionadas
								com o tratamento dos seus dados pessoais e com o exercício dos
								seus direitos. Contactos: geral@nexteam.pt Notificação e
								reclamação Sem prejuízo de envio de notificação direta a
								NEXTEAM, através dos contactos abaixo indicados, pode reclamar
								diretamente junto da Autoridade de Controlo, a Comissão Nacional
								de Proteção de Dados (CNPD), utilizando os contactos
								disponibilizados por esta entidade para o efeito. Política de
								Cookies Utilizamos cookies para melhorar o desempenho e a sua
								experiência como utilizador no nosso site. O que são Cookies? As
								Cookies são pequenos ficheiros de texto que um site, quando
								visitado, coloca no computador do utilizador ou no seu
								dispositivo móvel, através do browser. A colocação de Cookies
								ajudará o site a reconhecer o seu dispositivo numa próxima
								visita. As cookies retêm apenas informação relacionada com as
								suas preferências, não incluindo, como tal, os seus dados
								pessoais. Para que servem as Cookies? As cookies são utilizadas
								para ajudar a determinar a utilidade, interesse e o número de
								utilizações dos sites, permitindo uma navegação mais rápida e
								eficiente e eliminando a necessidade de introduzir repetidamente
								as mesmas informações. Que tipos de Cookies existem? Podem ser
								utilizados dois grupos de cookies: Cookies permanentes - Ficam
								armazenadas ao nível do navegador de internet (browser) nos seus
								dispositivos de acesso (PC, mobile e tablet) e que são
								utilizadas sempre que o utilizador faz uma nova visita ao nosso
								site. Geralmente são utilizadas para direcionar a navegação de
								acordo com os interesses do utilizador, permitindo-nos prestar
								um serviço mais personalizado. Cookies de sessão - São
								temporárias, permanecem no arquivo de cookies do seu navegador
								de internet (browser) até sair do site. A informação obtida
								através destas cookies permite identificar problemas e fornecer
								uma melhor experiência de navegação. Que tipos de Cookies
								utilizamos e para que fins? Cookies essenciais - Algumas cookies
								são essenciais para aceder a áreas específicas do nosso site.
								Permitem a navegação no site e a utilização das suas aplicações,
								tal como aceder a áreas seguras do site através de login. Sem
								estas cookies, os serviços que o exijam não podem ser prestados.
								Cookies analíticas - Utilizamos estas cookies para analisar a
								forma como os utilizadores usam o site e monitorar a performance
								deste. Isto permite-nos fornecer uma experiência de alta
								qualidade ao personalizar a nossa oferta e rapidamente
								identificar e corrigir quaisquer problemas que surjam. Estas
								cookies são utilizadas anonimamente apenas para efeitos de
								criação e análise estatística, sem nunca recolher informação de
								caráter pessoal. Cookies de funcionalidade - As cookies de
								funcionalidade guardam as preferências do utilizador
								relativamente à utilização do site, de forma que não seja
								necessário voltar a configurar o site cada vez que o visita.
								Como pode gerir as Cookies? Depois de autorizar o uso de
								Cookies, o utilizador pode sempre desativar parte ou a
								totalidade das nossas cookies. Todos os browsers permitem ao
								utilizador aceitar, recusar ou apagar cookies, nomeadamente
								através da seleção das definições apropriadas no respetivo
								navegador. Pode configurar as Cookies no menu “opções” ou
								“preferências” do seu browser. Note-se, no entanto, que, ao
								desativar as cookies, pode impedir que alguns serviços da web
								funcionem corretamente, afetando, parcial ou totalmente, a
								navegação no website. Mais informação sobre cookies em:
								www.allaboutcookies.org CONTACTOS: NEXTEAM - Nro 6 4740-229
								Esposende Portugal Tel: +351 253 051 560 (Chamada para a rede
								fixa nacional) Para questões relacionadas com o site/dominio e
								tratamento de dados pessoais: geral@nexteam.pt Atualizações a
								esta Política de Privacidade A NEXTEAM reserva o direito de, a
								qualquer altura, proceder a reajustamentos ou alterações à
								presente Política de Proteção de Dados Pessoais, sendo essas
								alterações devidamente publicitadas.
							</Typography>
						</Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
