import styled from "@emotion/styled";
import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	background: "#fc4a1a" /* fallback for old browsers */,
	background:
		"-webkit-linear-gradient(to right, #f7b733, #fc4a1a)" /* Chrome 10-25, Safari 5.1-6 */,
	background:
		"linear-gradient(to right, #f7b733, #fc4a1a)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,

	height: "50vh",
	width: "20vw",
	color: "#fff",
	lineHeight: "60px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
}));
export default function InNumbers() {
	return (
		<Grid
			container
			direction="column"
			width="100vw"
			minHeight="100vh"
			justifyContent={"space-around"}
			alignItems="center">
			<Grid item>
				<Typography variant="h2">A nossa empresa em Numeros</Typography>
			</Grid>
			<Grid item>
				<Grid
					container
					direction="row"
					width="100vw"
					justifyContent={"space-around"}
					alignItems="center">
					<Grid item>
						<Item elevation={8} className="floatShadow">
							<Grid
								container
								direction="column"
								justifyContent={"space-around"}
								alignItems="center">
								<Grid item>
									<Typography variant="h3">93%</Typography>
								</Grid>
								<Grid item>
									<Typography variant="caption">
										Clientes Satisfeitos
									</Typography>
								</Grid>
							</Grid>
						</Item>
					</Grid>
					<Grid item>
						<Item elevation={8} className="floatShadow">
							<Grid
								container
								direction="column"
								justifyContent={"space-around"}
								alignItems="center">
								<Grid item>
									<Typography variant="h3">+58,400</Typography>
								</Grid>
								<Grid item>
									<Typography variant="caption">
										intervenções técnicas
									</Typography>
								</Grid>
							</Grid>
						</Item>
					</Grid>
					<Grid item>
						<Item elevation={8} className="floatShadow">
							<Grid
								container
								direction="column"
								justifyContent={"space-around"}
								alignItems="center">
								<Grid item>
									<Typography variant="h3">+100 mil</Typography>
								</Grid>
								<Grid item>
									<Typography variant="caption">
										linhas de código escritas
									</Typography>
								</Grid>
							</Grid>
						</Item>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
