import { Grid, Typography } from "@mui/material";
import logo from "../media/logo_full_size.png";
import React from "react";
import { Zoom } from "react-reveal";
export default function IntroM() {
	return (
		<Grid
			container
			direction="column"
			minHeight="100vh"
			maxWidth="100vw"
			justifyContent={"space-around"}
			alignItems={"center"}
			ml={"5vw"}>
			<Grid item>
				<img src={logo} width="300"></img>
			</Grid>
		</Grid>
	);
}
