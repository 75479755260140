import Grid from "@mui/system/Unstable_Grid/Grid";
import { Typography } from "@mui/material";
import React from "react";

import Cookies from "../law/cookies";
import CookiesPolicy from "../law/cookies-policy";
import PrivacyPolicy from "../law/privacy-policy";
import TermsAndConditions from "../law/terms-and-conditions";
import Book from "../law/book";
const Footer = () => {
	return (
		<Grid
			container
			spacing={2}
			direction={"column"}
			justifyContent="center"
			alignItems="center"
			width="100vw"
			height="25vh"
			ml={2}>
			<Grid sx={{ borderTop: "1px solid rgba(0,0,0,0.5)" }} width="80vw"></Grid>
			<Grid>
				<Grid
					container
					spacing={2}
					direction={"row"}
					justifyContent="space-around"
					alignItems="center"
					width="100vw">
					<Grid item>
						<Typography variant="h5" width={"25vw"}>
							Rua Bombeiro Abilio Nunes Novo nº6, Esposende, 4740-229
						</Typography>
						<Typography variant="overline">
							info@nexteam.pt | 253 968 288
						</Typography>
					</Grid>
					<Grid>
						<Cookies />
						<CookiesPolicy />
					</Grid>
					<Grid>
						<PrivacyPolicy />
					</Grid>
					<Grid>
						<TermsAndConditions />
					</Grid>
					<Grid>
						<Book />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Footer;
