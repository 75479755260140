import { Grid, Paper, Typography } from "@mui/material";
import phone from "../media/cloud-phone.png";
import web from "../media/webapp.png";
import wallet from "../media/digital_wallet.png";
import React from "react";
import { Zoom } from "react-reveal";

import flyingThing from "../media/flying things.png";
import styled from "@emotion/styled";
const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	backgroundColor: "#f3f3f3",
	height: "40vh",
	color: "white",
	lineHeight: "60px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	borderRadius: "10px",
	zIndex: 20,
	margin: 0,
	"&:hover": {
		height: "41vh",
		backgroundColor: "#B7b9b6",
	},
}));
const Item2 = styled(Paper)(({ theme }) => ({
	...theme.typography.caption,
	textAlign: "center",
	backgroundColor: "rgba(0,0,0,0.5)",
	height: "35vh",
	color: "white",
	lineHeight: "50px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	borderRadius: "10px",
	zIndex: -10,
	margin: -30,
}));

export default function DevelopmentM() {
	return (
		<Grid
			container
			direction="column"
			width="90vw"
			justifyContent={"space-around"}
			ml={"10vw"}>
			<Grid item>
				<Grid item>
					<Typography variant="h2">Desenvol vimento</Typography>
				</Grid>
				<Grid item>
					<Typography variant="overline">
						Desenvolvemos Softwares a medida para ajudar a otimizar operações na
						sua empresa!
					</Typography>
				</Grid>
				<Grid
					container
					direction="column"
					justifyContent={"space-around"}
					width="90vw">
					<Grid item maxWidth="30vw">
						<Typography variant="h4"> Mobile</Typography>
						<Typography variant="overline">
							Aplicações Mobile Construidas de raiz utilizando React Native.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid
					container
					direction="column"
					justifyContent={"space-around"}
					width="90vw">
					<Grid item maxWidth="30vw">
						<Typography variant="h4">Web</Typography>
						<Typography variant="overline">
							Aplicações WEB Construidas de raiz utilizando React.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid
					container
					direction="column"
					justifyContent={"space-around"}
					alignItems={"center"}
					width="90vw">
					<Grid item maxWidth="30vw">
						<Typography variant="h4">Blockchain</Typography>
						<Typography variant="overline">
							Aplicações Reais com utilização da Blockchain.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
