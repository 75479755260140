import { Grid, Typography } from "@mui/material";
import React from "react";
import MyTeck from "../media/proposal.png";
export default function PortfolioM() {
	return (
		<Grid
			container
			direction="column"
			width="90vw"
			justifyContent={"space-around"}
			ml={"10vw"}>
			<Grid>
				<Typography maxWidth="100vw" variant="h2">
					Portefól io
				</Typography>
			</Grid>
			<Grid
				container
				direction="column"
				justifyContent={"space-around"}
				width={"90vw"}>
				<Grid item>
					<img src={MyTeck} width="200"></img>
				</Grid>
				<Grid item maxWidth="30vw">
					<Typography variant="h4">
						MyTeck <br></br>- gestão de <br></br> Equipa técnica
					</Typography>
					<Typography variant="overline">
						Software as a Product In-house utilizado para gestão da nossa equipa
						técnica, que evoluiu para um Software as a Service
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
}
